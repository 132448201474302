<template>
    <div>
        <ValidationObserver ref="formModalValidate">
           <b-row>
                <b-col cols="12" md="6">
                  <ValidationProvider name="title" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('title')">
                            <b-form-input :validate-error="errors[0]" v-model="form.title">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="season" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('season')">
                            <parameter-selectbox v-model="form.season" :multiple="true"
                                                 :validate-error="errors[0]" code="season"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="vote_count" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('vote_count')">
                            <b-form-input :validate-error="errors[0]" v-model="form.vote_count">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
					</ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="form.start_date" :is-time="true" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                  <ValidationProvider name="sms_message" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('sms_message')">
                            <b-form-input :validate-error="errors[0]" v-model="form.sms_message">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="form.end_date" :is-time="true" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                 <b-col cols="12" md="6">
                    <ValidationProvider name="position" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('position')">
                            <parameter-selectbox v-model="form.position" :multiple="true"
                                                 :validate-error="errors[0]" code="position"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                 <b-col cols="12" md="6">
                    <ValidationProvider name="clup" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('clup')">
                            <parameter-selectbox v-model="form.clup" :multiple="true"
                                                 :validate-error="errors[0]" code="student_clup"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="form.status" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>   
            </b-row>
            <b-button
                @click="createForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </ValidationObserver>
    </div>
</template>

<script>
    //Component
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import StudentClubSelectionSearchService from "@/services/StudentClubSelectionSearchService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,

            ParameterSelectbox,
            StatusSelectbox
        },
        data() {
            return {
                formLoading: false,
                form: {
                  //
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.formLoading = true;
                    /*StudentClubSelectionSearchService.store(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    }).finally(() => {
                        this.formLoading = false;
                    });*/
                }
            }
        }
    }
</script>
