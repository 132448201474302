import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/classrooms', config);
}

const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/classrooms/' + id);
}
const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/classrooms/' + code);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/classrooms/' + data.id, data);
    }
    return Api.post('/classrooms', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/classrooms/' + id, formData);
}
const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/classrooms/' + id);
}

const downloadAsExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/classrooms/excel-export',{...config,responseType:'arraybuffer'})
}

const getAllLists = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/classrooms', config);
}

export default {
    getAll,
    show,
    get,
    update,
    store,
    del,
    downloadAsExcel,
    getAllLists
}
