<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_club_selection_search')"
                        :isFilter="false"
                        :isNewButton="checkPermission('classroom_store')"
                        @new-button-click="createFormShow">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_club_selection_search')"
                              :isFilter="false"
                              :isNewButton="checkPermission('classroom_store')"
                              @new-button-click="createFormShow">
                    <div class="mb-1" v-for="(column,key) in datatable.columns">
                        <b-form-checkbox
                            :id="'checkbox-'+key"
                            :name="'checkbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </HeaderMobile>
            </template>

            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :export-excel="checkPermission('classroom_excelexport')"
                              @exportExcel="downloadExcel"
            >
                <b-row>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('title')">
                            <b-form-input v-model="datatable.queryParams.filter.title"/>
                        </b-form-group>
                    </b-col>
                     <b-col sm="12" md="6">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="datatable.queryParams.filter.status"
                            ></status-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('start_date')">
                            <select-date v-model="datatable.queryParams.filter.start_date"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('season')">
                            <parameter-selectbox code="season"
                                v-model="datatable.queryParams.filter.season" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('end_date')">
                            <select-date v-model="datatable.queryParams.filter.end_date"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('classroom_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('classroom_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    //Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    //Component
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';
    import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    //Page
    import CreateForm from './CreateForm'
    import UpdateForm from './UpdateForm'

    //Service
    import StudentClubSelectionSearchService from '@/services/StudentClubSelectionSearchService';

    //Other
    import qs from 'qs';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,
            StatusSelectbox,
            ParameterSelectbox,

            CreateForm,
            UpdateForm,
        },
        metaInfo() {
            return {
                title: this.$t('student_club_selection_search')
            }
        },
        data() {
            return {
                formId: 0,
                form: {},
                formProcess: null,
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            hidden: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'classroom_update',
                                    callback: (row) => {
                                        this.formId = row.id;
                                        this.loadData();
                                    }
                                },
                                {
                                    text: this.$t('members'),
                                    class: 'ri-list-check',
                                   // permission: 'classroom_update',
                                    callback: (row) => {
                                        this.$router.push('/student/clubs/' + row.id + '/members')
                                    }
                                },
                                {
                                    text: this.$t('sms_list'),
                                    class: 'ri-file-list-line',
                                    permission: 'classroom_update',
                                    callback: (row) => {
                                        this.$router.push('/student/clubs/' + row.id + '/sms-list')
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    permission: 'classroom_delete',
                                    callback: (row) => {
                                        this.delete(row.id);
                                    }
                                },
                                
                            ]
                        },
                        {
                            label: this.toUpperCase('title'),
                            field: 'title',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('vote_count'),
                            field: 'vote_count',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('start_date'),
                            field: 'start_date',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('end_date'),
                            field: 'end_date',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('season'),
                            field: 'season',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('status'),
                            field: "status",
                            hidden: false,
                            formatFn:(row)=>{
                                return row ==='a'?'Aktif':'Pasif'
                            }
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },

            };
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return StudentClubSelectionSearchService.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data;
                                           this.datatable.total = response.data.pagination.total;
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false;
                                       });
            },
            loadData() {
                this.formProcess = 'update';
                StudentClubSelectionSearchService.get(this.formId)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.$refs.updateFormModal.$refs.commonModal.show();
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    }
                                });
            },
            delete(id) {
                this.deleteModal(() => {
                    StudentClubSelectionSearchService.del(id)
                                    .then((response) => {
                                        this.filter();
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    });
                });
            },
            async createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            async updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            createFormShow() {
                this.formId = null
                this.form = {};
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            formClear() {
                this.formId = null
                this.formProcess = null
            },
            downloadExcel() {
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                StudentClubSelectionSearchService.downloadAsExcel(config)
                                .then(res => this._downloadFile(res, this.$t('club_selection_search') + '.xlsx'))
                                .catch(err => this.showErrors(err))
            },

        }
    }
</script>
